// import logo from './logo.svg';
import './App.css';
// import KateSpringPhoto from './assets/obsession-method-KateSpringLarge.jpg'
import PDFCover from './assets/Personalized-Soul-Path-1.png'
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { projectFirestore } from "./firebase/config";

function App() {
  const [user_name, setName] = useState("");
    const [user_email, setEmail] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();
      // document.getElementById("dvMesssage").classList.remove("SuccessMessage");
      // document.getElementById("dvMesssage").classList.remove("ErrorMessage");
      projectFirestore.collection('lead').add({
        name: user_name,
        email: user_email,
        
        
      }).then(() => {
        window.location.href = "https://soul-manifestation.com/"
        
      }).catch((error) => {
        document.getElementById("dvMesssage").className = "ErrorMessage";
        document.getElementById("dvMesssage").innerHTML=error.message;
      });
      setName("");
      setEmail("");
      
    };
       
    // var enableSend = function handleOnChange(value) {
    //   const btn = document.getElementById('sendBtn');
    //   btn.setAttribute('disabled', '')
    //   document.getElementById("dvMesssage").classList.remove("SuccessMessage");
    //   document.getElementById("dvMesssage").classList.remove("ErrorMessage");

    //   if (value != null) {
    //     //end able send button
    //     btn.removeAttribute('disabled')
    //   }
    // }  


  return (
    <div className="App">
      <header className="App-header">
        <h2>Soul Manifestation</h2>
        <h1>Get Your <span className="italics underline">FREE</span> Personalized Soul Path Report!</h1>
        <form onSubmit={handleSubmit}>
          <input type="name" placeholder="First Name" required value={user_name} onChange={(e) => setName(e.target.value)}></input>
          <input type="email" placeholder="Email" required value={user_email} onChange={(e) => setEmail(e.target.value)}></input>
          <button id="sendBtn"  type="submit" className="btn-primary">GET MY FREE REPORT</button>
          {/* set button back to disabled once recaptcha working */}
        </form>
        <img className="images" alt="cover" src={PDFCover}></img>
        <p>This is a FREE service from Soul-Manifestation.com <br></br>Credit card NOT required. </p>
        <p>Your information is 100% secure with us and will never be shared with anyone.</p>
        <p className="footer-copyright">© 2023 - Privacy Policy / Terms of Service / Disclaimer / Support</p>
      </header>
    </div>
  );
}

export default App;
