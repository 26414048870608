import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/app-check'
//import firebaseConfig from './firebase-config.js'

// {/* Official TETHERED Firebase API */}
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyC0aLZSpN-7PqQYQToz_K7S8CLMnF8mPjM",
  authDomain: "manifestation-landing-page.firebaseapp.com",
  projectId: "manifestation-landing-page",
  storageBucket: "manifestation-landing-page.appspot.com",
  messagingSenderId: "1015713904586",
  appId: "1:1015713904586:web:4074334cebf1e57ed20133",
  measurementId: "G-764L06MX8S"
  };

  // init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

// Firebase app check

// const appCheck = firebase.appCheck()
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   '6LdhOnckAAAAAO5SSkiM3XJLfgODyUBqVT7JR3H1')

export { projectFirestore, projectAuth, timestamp, projectStorage}
